import { Button, TextInput } from "@getwellen/valesco";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { TagIcon, XMarkIcon } from "@heroicons/react/24/solid";
import LoadingSpinner from "components/loading/LoadingSpinner";
import usePrevious from "hooks/usePrevious";
import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

const PromoCodeEntryContainer: React.FC<{ children: React.ReactNode }> = ({
  children
}) => <div className="flex w-full flex-col items-start">{children}</div>;

type PromoCodeEntryProps = {
  /** Style variant - affects colors and text styles */
  variant?: "cello" | "geebung";
  /** Whether to show the promo code input field */
  showInput: boolean;
  /** The currently applied promo code */
  appliedPromoCode: string | null;
  /** Optional description to show below an applied promo code */
  appliedPromoCodeDescription?: React.ReactNode;
  /** Current value of the promo code input */
  promoCode: string;
  /** Error message to display if promo code validation fails */
  promoCodeError?: string;
  /** Whether the component is currently submitting/validating a code */
  isSubmitting?: boolean;
  /** Callback when promo code input changes */
  onPromoCodeChanged: (promoCode: string) => void;
  /** Callback when promo code form is submitted */
  onPromoCodeAppliedClicked: (e: React.FormEvent<HTMLFormElement>) => void;
  /** Callback when "Add promo code" button is clicked */
  onAddPromoCodeClicked: (e: React.SyntheticEvent) => void;
  /** Callback when remove promo code button is clicked */
  onRemovePromoCodeClicked: (e: React.SyntheticEvent) => void;
};

const PromoCodeEntry: React.FC<PromoCodeEntryProps> = ({
  variant = "cello",
  showInput = false,
  appliedPromoCode = null,
  appliedPromoCodeDescription,
  promoCode = "",
  promoCodeError = undefined,
  onPromoCodeChanged,
  onPromoCodeAppliedClicked,
  onAddPromoCodeClicked,
  onRemovePromoCodeClicked,
  isSubmitting = false
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const prevShowInput = usePrevious<boolean>(showInput);

  useEffect(() => {
    if (prevShowInput !== showInput && showInput) {
      formRef.current?.querySelector("input")?.focus();
    }
  }, [showInput, prevShowInput]);

  return (
    <PromoCodeEntryContainer>
      {/* Add Promo Code Button */}
      {!showInput &&
        (!appliedPromoCode || Number(appliedPromoCode?.length) === 0) && (
          <button
            className="inline-flex grow items-center gap-x-2"
            onClick={onAddPromoCodeClicked}
          >
            <PlusCircleIcon
              className={twMerge(
                "h-5 w-5",
                variant === "cello" ? "fill-cello-500" : "fill-geebung-500"
              )}
            />
            <span
              className={twMerge(
                "grow text-left",
                variant === "cello"
                  ? "text-cello-500"
                  : "font-semibold text-geebung-500"
              )}
            >
              Add promo code or gift card
            </span>
          </button>
        )}

      {/* Applied Promo Code Display */}
      {!showInput &&
        appliedPromoCode &&
        Number(appliedPromoCode.length) > 0 && (
          <span className="flex w-full flex-col items-stretch gap-y-4">
            <span className="flex w-full items-center gap-x-2 rounded-lg bg-green px-4 py-3 text-white">
              <TagIcon className="size-6" />
              <span className="grow text-base font-semibold">
                {appliedPromoCode}
              </span>
              <button
                aria-label="Remove promo code"
                onClick={onRemovePromoCodeClicked}
              >
                <XMarkIcon className="size-6" />
                <span className="sr-only">Remove promo code</span>
              </button>
            </span>
            {appliedPromoCodeDescription}
          </span>
        )}

      {/* Promo Code Input Form */}
      <form
        ref={formRef}
        className={twMerge(
          "inline-flex",
          promoCodeError ? "items-start" : "items-end",
          showInput ? "inline-flex md:visible" : "hidden md:invisible",
          variant === "cello" ? "w-auto" : "w-full"
        )}
        onSubmit={onPromoCodeAppliedClicked}
      >
        <TextInput
          className="mr-3"
          disabled={isSubmitting}
          error={!!promoCodeError}
          helpText={promoCodeError}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onPromoCodeChanged(e.target.value);
          }}
          placeholder="Promo code"
          value={promoCode}
        />
        <Button
          action="secondary"
          className={twMerge(
            "h-full w-24 text-center",
            isSubmitting ? "h-14 w-28" : ""
          )}
          disabled={isSubmitting || promoCode.length < 3}
          type="submit"
        >
          {isSubmitting ? <LoadingSpinner /> : "Apply"}
        </Button>
      </form>
    </PromoCodeEntryContainer>
  );
};

export { PromoCodeEntry };
