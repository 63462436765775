import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { useEnv } from "contexts/EnvContext";
import { useGetOrderPaymentIntentQuery } from "graphql/rails-api";
import React, { createContext, useContext, useState } from "react";
import { stripeElementsOptions } from "utils/stripe";

interface PaymentIntentContextType {
  stripeClientSecret?: string;
  paymentIntentLoading: boolean;
}

const defaultState: PaymentIntentContextType = {
  stripeClientSecret: undefined,
  paymentIntentLoading: false
};

const PaymentIntentContext =
  createContext<PaymentIntentContextType>(defaultState);

const PaymentIntentProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const { data: paymentIntentData, loading: paymentIntentLoading } =
    useGetOrderPaymentIntentQuery({
      context: { clientName: "rails-api" },
      fetchPolicy: "network-only"
    });

  const { STRIPE_API_KEY } = useEnv();
  const [stripePromise] = useState(() => loadStripe(STRIPE_API_KEY || ""));

  const clientSecret =
    paymentIntentData?.getOrderPaymentIntent.clientSecret || undefined;

  return (
    <PaymentIntentContext.Provider
      value={{
        stripeClientSecret: clientSecret,
        paymentIntentLoading
      }}
    >
      {!paymentIntentLoading && clientSecret ? (
        <Elements
          options={{
            ...stripeElementsOptions,
            // @ts-expect-error: stripe seems to think that clientSecret and all other options are mututally exclusive but they are not
            clientSecret: clientSecret,
            mode: undefined,
            // @ts-expect-error: stripe seems to think that clientSecret and all other options are mututally exclusive but they are not
            paymentMethodCreation: "manual"
          }}
          stripe={stripePromise}
        >
          {children}
        </Elements>
      ) : (
        <LoadingSpinner />
      )}
    </PaymentIntentContext.Provider>
  );
};

const usePaymentIntent = () => {
  const context = useContext(PaymentIntentContext);

  if (!context)
    throw new Error(
      "usePaymentIntent must be used within PaymentIntentProvider"
    );

  return context;
};

export { PaymentIntentContext, PaymentIntentProvider, usePaymentIntent };
