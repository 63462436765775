import { FetchResult } from "@apollo/client";
import {
  useValidatePromoCodeMutation,
  ValidatePromoCodeMutation
} from "graphql/rails-api";
import {
  SubscriptionPlan,
  useSubscriptionPlansQuery
} from "graphql/strapi-cms";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { useGeolocation } from "./GeolocationContext";

interface PromoCodeContextInterface {
  promoCode: string;
  stripePriceId: string;
  setPromoCode: React.Dispatch<React.SetStateAction<string>>;
  setStripePriceId: React.Dispatch<React.SetStateAction<string>>;
  setStripeProductId: React.Dispatch<React.SetStateAction<string>>;
  validatePromoCodeWithPriceId(
    priceId?: string,
    promoCode?: string
  ): Promise<FetchResult<ValidatePromoCodeMutation>>;
  validatePromoCodeWithProductId(
    productId?: string,
    promoCode?: string
  ): Promise<FetchResult<ValidatePromoCodeMutation>>;
  clearAutoApplyPromoCode: () => void;
  autoApplyPromoCode: AutoApplyCouponCode | undefined;
}

const defaultState: PromoCodeContextInterface = {
  promoCode: "",
  stripePriceId: "",
  setPromoCode: () => null,
  setStripePriceId: () => null,
  setStripeProductId: () => null,
  validatePromoCodeWithPriceId: async () =>
    ({}) as FetchResult<ValidatePromoCodeMutation>,
  validatePromoCodeWithProductId: async () =>
    ({}) as FetchResult<ValidatePromoCodeMutation>,
  clearAutoApplyPromoCode: () => null,
  autoApplyPromoCode: undefined
};

const PromoCodeContext = createContext<PromoCodeContextInterface>(defaultState);

type AutoApplyCouponCode = {
  isValid: boolean;
  stripePriceId: string;
  promoCode: string;
};

const PromoCodeProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [autoApplyPromoCode, setAutoApplyPromoCode] = useLocalStorage<
    AutoApplyCouponCode[]
  >("wellen.autoApplyPromoCode", []);
  const [promoCode, setPromoCode] = useState<string>("");
  const [stripePriceId, setStripePriceId] = useState<string>("");
  const [stripeProductId, setStripeProductId] = useState<string>("");
  const [_validatePromoCode] = useValidatePromoCodeMutation();
  const { country_code } = useGeolocation();
  const location = useLocation();
  const [params] = useSearchParams();

  const { data: plansData, loading: plansIsLoading } =
    useSubscriptionPlansQuery({
      fetchPolicy: "cache-first"
    });

  const plans = (plansData?.subscriptionPlans?.data || []).map(
    (p) => p.attributes as SubscriptionPlan
  );

  // Updated validation functions with optional arguments
  const validatePromoCodeWithPriceId = useCallback(
    (providedPriceId?: string, promoCodeOverride?: string) => {
      const finalPriceId = providedPriceId || stripePriceId;

      if (!finalPriceId) {
        throw new Error("No stripe price ID provided or found in context");
      }

      return _validatePromoCode({
        context: { clientName: "rails-api" },
        variables: {
          code: promoCodeOverride || promoCode,
          stripePriceId: finalPriceId,
          countryCode: country_code
        }
      });
    },
    [_validatePromoCode, promoCode, stripePriceId, country_code]
  );

  const validatePromoCodeWithProductId = useCallback(
    (providedProductId?: string, promoCodeOverride?: string) => {
      const finalProductId = providedProductId || stripeProductId;

      if (!finalProductId) {
        throw new Error("No stripe product ID provided or found in context");
      }

      return _validatePromoCode({
        context: { clientName: "rails-api" },
        variables: {
          code: promoCodeOverride || promoCode,
          stripeProductId: finalProductId,
          countryCode: country_code
        }
      });
    },
    [_validatePromoCode, promoCode, stripeProductId, country_code]
  );

  const validatePromoCodes = async (promoCode: string) => {
    setAutoApplyPromoCode(
      await Promise.all(
        plans.map(async (plan) => {
          const result = await validatePromoCodeWithPriceId(
            plan.stripePriceId,
            promoCode
          );
          return {
            promoCode,
            isValid: !!result.data?.validatePromoCode.isValid,
            stripePriceId: plan.stripePriceId
          };
        })
      )
    );
  };

  const clearAutoApplyPromoCode = () => {
    setAutoApplyPromoCode([]);
  };

  useEffect(() => {
    const promoCode: string | null = params.get("promo_code");

    // url based promo codes
    if (promoCode && !plansIsLoading) {
      setPromoCode("");
      void validatePromoCodes(promoCode);
    }
  }, [location, plansIsLoading]);

  useEffect(() => {
    const autoApply = (autoApplyPromoCode || []).find(
      (p) => p.isValid && p.stripePriceId === stripePriceId
    );

    if (autoApply) setPromoCode(autoApply.promoCode);
  }, [stripePriceId]);

  return (
    <PromoCodeContext.Provider
      value={{
        promoCode,
        stripePriceId,
        setPromoCode,
        setStripePriceId,
        setStripeProductId,
        validatePromoCodeWithPriceId,
        validatePromoCodeWithProductId,
        clearAutoApplyPromoCode,
        autoApplyPromoCode: (autoApplyPromoCode || []).find(
          (p) => p.stripePriceId === stripePriceId
        )
      }}
    >
      {children}
    </PromoCodeContext.Provider>
  );
};

const usePromoCode = () => {
  const context = useContext(PromoCodeContext);

  if (!context)
    throw new Error("usePromoCode must be used within PromoCodeProvider");

  return context;
};

export { PromoCodeProvider, usePromoCode };
